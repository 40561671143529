.login-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

.login-form {
    width: 420px;
    box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
    padding-top: 30px;
    padding-bottom: 50px;
    border-radius: 8px;
    background-color: white;
}

.login-form-content {
    padding-left: 12%;
    padding-right: 12%;
}

.login-form-title {
    text-align: center;
    margin-bottom: 1em;
    font-size: 24px;
    color: rgb(34, 34, 34);
    /*font-weight: 800;*/
}

label {
    font-size: 14px;
    font-weight: 600;
    color: rgb(34, 34, 34);
}

.is-danger.input, .is-danger.textarea {
    border-color: #f14668;
}

.help.is-danger {
    color: #f14668;
}

.help {
    display: block;
    font-size: 0.75rem;
    margin-top: 0.25rem;
}

.justify-center {
    display: flex;
    justify-content: center;
}
