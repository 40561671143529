@media only screen and (max-width: 768px) {
    div.jss5 {
        margin-top: 54px;
        margin-left: 17px !important;
        margin-right: 56px;
        margin-bottom: 17px !important;
    }

    /* div.jss5 img {
        height: 68px !important;
    }

    div.jss9 img {
        height: 68px !important;
    } */
}